<template>
  <div id="submit" class="component">
    <div id="confirmation-title">Potwierdzenie wpłaty: (max 5 MB)</div>
    <div id="confirmation" class="center"><input type="file" @change="uploadFile($event)" id="confirmation-file"
                                  accept="application/pdf, image/png, image/jpeg" name="confirmation"></div>
    <div id="email-title" class="center">Wyślij zgłoszenie na email:</div>
    <div id="email-op1" class="center">
      <input type="radio" name="email" class="better-radio" id="email-op1-radio" value="Kierowcy" v-model="data.email"
             :class="[{ 'checked': data.email==='Kierowcy' }]">
      <label for="email-op1-radio" name="email">
        <span>☑</span>
        Kierowcy
      </label>
    </div>
    <div id="email-op2" class="center">
      <input type="radio" name="email" class="better-radio" id="email-op2-radio" value="Pilota" v-model="data.email"
             :class="[{ 'checked': data.email==='Pilota' }]">
      <label for="email-op2-radio" name="email">
        <span>☑</span>
        Pilota
      </label>
    </div>
    <div id="email-op3" class="center">
      <input type="radio" name="email" class="better-radio" id="email-op3-radio" value="Inny" v-model="data.email"
             :class="[{ 'checked': data.email==='Inny' }]">
      <label for="email-op3-radio" name="email">
        <span>☑</span>
        Inny
      </label>
    </div>
    <div id="empty"></div>
    <div id="email-other" class="input-div"><input type="email" name="emailInput" :disabled="data.email!=='Inny'"
                                 v-model="data.emailInput"></div>
  </div>
</template>

<script>

export default {
  name: "RegistrationSubmit",
  props: {
    objectName: {
      type: String,
      required: true,
    },
    competitorsData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isAll: false,
      requiredFocus: false,
      data: {
        email: "",
        confirmation: null,
        emailInput: ""
      },
      required: {
        email: true,
        confirmation: false,
        emailInput: true,
      },
    }
  },
  methods: {
    focusRequired: function () {
      for (const [key, value] of Object.entries(this.required))
        if (value && !this.data[key]) {
          document.getElementsByName(key).forEach(el => el.classList.add("is-required"))
          this.requiredFocus = true
        }
    },
    clearRequiredFocus: function () {
      for (const [key, value] of Object.entries(this.required))
        if (value && this.data[key]) {
          document.getElementsByName(key).forEach(el => el.classList.remove("is-required"))
        }
    },
    autoFill: function(data) {
      this.data.email= "Inny"
      this.data.emailInput = data
    },
    uploadFile: function (event) {
      const files = event.target.files || event.dataTransfer.files;
      if (files.length !== 1) {
        document.getElementsByName("confirmation").forEach(el => el.classList.add("is-required"))
        return;
      }
      if(files[0].size> 5 * 1024 * 1024) {
        document.getElementsByName("confirmation").forEach(el => el.classList.add("is-required"))
        return;
      }
      document.getElementsByName("confirmation").forEach(el => el.classList.remove("is-required"))
      this.data.confirmation = files[0];
    },
    refreshInputs: function () {
      this.isAll = true
      for (const [key, value] of Object.entries(this.required))
        if (value && !this.data[key]) {
          this.isAll = false;
          break
        }
      if (this.requiredFocus)
        this.clearRequiredFocus();
      this.$emit('update-data', {objectName: this.objectName, data: this.data})
      this.$emit('update-is-all', {objectName: this.objectName, data: this.isAll})
      if(this.data.email === "Kierowcy")
        this.data.emailInput = this.competitorsData.driverEmail
      else if(this.data.email === "Pilota")
        this.data.emailInput = this.competitorsData.codriverEmail
    }
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.refreshInputs();
      }
    },
    competitorsData: {
      deep: true,
      handler() {
        this.refreshInputs();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#submit {
  margin-top: 20px;
  grid-auto-rows: 1fr;
  grid-template-columns: 3fr 2fr 2fr 2fr;
  grid-template-areas:
      "confirmation-title email-title email-title email-title"
      "confirmation email-op1 email-op2 email-op3"
      "empty email-other email-other email-other";

  #confirmation-title {
    grid-area: confirmation-title;
  }

  #confirmation {
    grid-area: confirmation;
  }

  #email-title {
    grid-area: email-title;
  }

  #email-op1 {
    grid-area: email-op1;
  }

  #email-op2 {
    grid-area: email-op2;
  }

  #email-op3 {
    grid-area: email-op3;
  }

  #email-other {
    grid-area: email-other;
  }
  #empty {
    grid-area: empty;
  }
}
</style>
