<template>
  <GDialog v-model="dialogState" max-width="550" persistent>
    <div class="wrapper">
      <div class="content">
        <div class="title">Przetwarzanie zgłoszenia</div>
        <div class="loader" v-if="loadingDialog">
          <div class="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div v-else>
          <p v-if="!errorCode">
            Zgłoszenie wysłane!
            <br><br>
            Sprawdź poprawność wypełnionego zgłoszenia na swojej skrzynce email.
            <br>
            W przypadku problemów skontaktuj się z organizatorem.
          </p>
          <p v-else>
            Wystąpił błąd. Kod błędu: {{ errorCode }}. Skontaktuj się z organizatorem.
          </p>
        </div>
      </div>
      <div class="actions" v-if="!loadingDialog">
        <button
            class="btn btn--outline-gray"
            @click="dialogState = false"
        >
          OK
        </button>
      </div>
    </div>
  </GDialog>
</template>

<script>
export default {
  name: "RegistrationDialog",
  props: {
    openDialog: {
      type: Boolean,
      required: true
    },
    loadingDialog: {
      type: Boolean,
      required: true
    },
    errorCode: {
      type: String,
      required: false
    },
  },
  data() {
    return {
      dialogState: false,
    }
  },
  watch: {
    openDialog: function () {
      if (this.openDialog)
        this.dialogState = true
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  color: #000;
}

.content {
  padding: 20px;
}

.title {
  font-size: 120%;
  text-transform: none;
  margin-bottom: 20px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.lds-spinner {
  color: black;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;

    &:after {
      content: " ";
      display: block;
      position: absolute;
      top: 3px;
      left: 37px;
      width: 6px;
      height: 18px;
      border-radius: 20%;
      background: black;
    }

    &:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -1.1s;
    }

    &:nth-child(2) {
      transform: rotate(30deg);
      animation-delay: -1s;
    }

    &:nth-child(3) {
      transform: rotate(60deg);
      animation-delay: -0.9s;
    }

    &:nth-child(4) {
      transform: rotate(90deg);
      animation-delay: -0.8s;
    }

    &:nth-child(5) {
      transform: rotate(120deg);
      animation-delay: -0.7s;
    }

    &:nth-child(6) {
      transform: rotate(150deg);
      animation-delay: -0.6s;
    }

    &:nth-child(7) {
      transform: rotate(180deg);
      animation-delay: -0.5s;
    }

    &:nth-child(8) {
      transform: rotate(210deg);
      animation-delay: -0.4s;
    }

    &:nth-child(9) {
      transform: rotate(240deg);
      animation-delay: -0.3s;
    }

    &:nth-child(10) {
      transform: rotate(270deg);
      animation-delay: -0.2s;
    }

    &:nth-child(11) {
      transform: rotate(300deg);
      animation-delay: -0.1s;
    }

    &:nth-child(12) {
      transform: rotate(330deg);
      animation-delay: 0s;
    }
  }
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.loader {
  width: 100%;
  text-align: center;
}

</style>
