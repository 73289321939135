<template>
  <div id="ice" class="component">
    <div id="title" class="title">Kontakt I.C.E.</div>
    <div id="ice-phone-title">Telefon I.C.E.</div>
    <div id="ice-phone-driver" class="input-div"><input type="text" v-model="data.icePhoneDriver" name="icePhoneDriver"></div>
    <div id="ice-phone-codriver" class="input-div"><input type="text" v-model="data.icePhoneCodriver" name="icePhoneCodriver"></div>
  </div>
</template>

<script>
export default {
  name: "RegistrationICE",
  props: {
    objectName: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      isAll: false,
      requiredFocus: false,
      data: {
        icePhoneDriver: "",
        icePhoneCodriver: "",
      },
      required: {
        icePhoneDriver: true,
        icePhoneCodriver: false,
      },
    }
  },
  methods: {
    focusRequired: function () {
      for (const [key, value] of Object.entries(this.required))
        if (value && !this.data[key]) {
          document.getElementsByName(key).forEach(el => el.classList.add("is-required"))
          this.requiredFocus = true
        }
    },
    clearRequiredFocus: function () {
      for (const [key, value] of Object.entries(this.required))
        if (value && this.data[key]) {
          document.getElementsByName(key).forEach(el => el.classList.remove("is-required"))
        }
    },
    autoFill: function(data) {
      this.data = {...data}
    }
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.isAll = true
        for (const [key, value] of Object.entries(this.required))
          if (value && !this.data[key]) {
            this.isAll = false;
            break
          }
        if(this.requiredFocus)
          this.clearRequiredFocus();
        this.$emit('update-data', {objectName: this.objectName, data: this.data})
        this.$emit('update-is-all', {objectName: this.objectName, data: this.isAll})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#ice {
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
      "title title title"
      "ice-phone-title ice-phone-driver ice-phone-codriver";

  #title {
    grid-area: title;
  }

  #ice-phone-title {
    grid-area: ice-phone-title;
  }

  #ice-phone-driver {
    grid-area: ice-phone-driver;
  }

  #ice-phone-codriver {
    grid-area: ice-phone-codriver;
  }
}
</style>
