<template>
  <div id="car" class="component">
    <div id="title" class="title">Szczegóły dotyczące samochodu</div>
    <div id="brand-title">Marka</div>
    <div id="brand" class="input-div"><input type="text" v-model="data.brand" name="brand"></div>
    <div id="registration-title">Nr rejestracyjny</div>
    <div id="registration" class="input-div"><input type="text" v-model="data.registration" name="registration"></div>
    <div id="model-title">Model</div>
    <div id="model" class="input-div"><input type="text" v-model="data.model" name="model"></div>
    <div id="chassis-title">Nr nadwozia</div>
    <div id="chassis" class="input-div"><input type="text" v-model="data.chassis" name="chassis"></div>
    <div id="production-title">Rok produkcji</div>
    <div id="production" class="input-div"><input type="text" v-model="data.production" name="production"></div>
    <div id="capacity-title">Pojemność skokowa</div>
    <div id="capacity" class="input-div"><input type="text" v-model="data.capacity" name="capacity"></div>
    <div id="class-title">Klasa</div>
    <div id="class" class="input-div">
      <select v-model="data.carClass" name="carClass">
        <option v-for="item in classes" :value="item" :key="item">Klasa {{ item }}</option>
      </select>
    </div>
    <div id="turbo-title">Turbo</div>
    <div id="turbo-op1" class="center">
      <input type="radio" name="turbo" class="better-radio" id="turbo-op1-radio" value="Tak" v-model="data.turbo"
             :class="[{ 'checked': data.turbo==='Tak' }]">
      <label for="turbo-op1-radio" name="turbo">
        <span>☑</span>
        Tak
      </label>
    </div>
    <div id="turbo-op2" class="center">
      <input type="radio" name="turbo" class="better-radio" id="turbo-op2-radio" value="Nie" v-model="data.turbo"
             :class="[{ 'checked': data.turbo==='Nie' }]">
      <label for="turbo-op2-radio" name="turbo">
        <span>☑</span>
        Nie
      </label>
    </div>
    <div id="registration-country-title">Kraj rejestr.</div>
    <div id="registration-country" class="input-div"><input type="text" v-model="data.registrationCountry" name="registrationCountry"></div>
    <div id="counted-capacity-title">Przeliczona poj. skokowa</div>
    <div id="counted-capacity" class="input-div"><input type="text" v-model="data.countedCapacity" name="countedCapacity"></div>
  </div>
</template>

<script>
const config = require("@/const/config")
export default {
  name: "RegistrationCar",
  props: {
    objectName: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      classes: config.classes,
      isAll: false,
      requiredFocus: false,
      data: {
        brand: "",
        registration: "",
        model: "",
        chassis: "",
        production: "",
        capacity: "",
        carClass: null,
        turbo: null,
        registrationCountry: "",
        countedCapacity: "",
      },
      required: {
        brand: true,
        registration: true,
        model: true,
        chassis: true,
        production: true,
        capacity: true,
        carClass: true,
        turbo: true,
        registrationCountry: true,
        countedCapacity: true,
      },
    }
  },
  methods: {
    focusRequired: function () {
      for (const [key, value] of Object.entries(this.required))
        if (value && !this.data[key]) {
          document.getElementsByName(key).forEach(el => el.classList.add("is-required"))
          this.requiredFocus = true
        }
    },
    clearRequiredFocus: function () {
      for (const [key, value] of Object.entries(this.required))
        if (value && this.data[key]) {
          document.getElementsByName(key).forEach(el => el.classList.remove("is-required"))
        }
    },
    autoFill: function(data) {
      this.data = {...data}
    }
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.isAll = true
        for (const [key, value] of Object.entries(this.required))
          if (value && !this.data[key]) {
            this.isAll = false;
            break
          }
        if (this.requiredFocus)
          this.clearRequiredFocus();
        this.$emit('update-data', {objectName: this.objectName, data: this.data})
        this.$emit('update-is-all', {objectName: this.objectName, data: this.isAll})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#car {
  grid-template-rows: auto;
  grid-template-columns: 2fr 2fr 3fr 1fr 1fr;
  grid-template-areas:
      "title title title title title"
      "brand-title brand registration-title registration registration"
      "model-title model chassis-title chassis chassis"
      "production-title production capacity-title capacity capacity"
      "class-title class turbo-title turbo-op1 turbo-op2"
      "registration-country-title registration-country counted-capacity-title counted-capacity counted-capacity";

  #title {
    grid-area: title;
  }

  #brand-title {
    grid-area: brand-title;
  }

  #brand {
    grid-area: brand;
  }

  #registration-title {
    grid-area: registration-title;
  }

  #registration {
    grid-area: registration;
  }

  #model-title {
    grid-area: model-title;
  }

  #model {
    grid-area: model;
  }

  #chassis-title {
    grid-area: chassis-title;
  }

  #chassis {
    grid-area: chassis;
  }

  #production-title {
    grid-area: production-title;
  }

  #production {
    grid-area: production;
  }

  #capacity-title {
    grid-area: capacity-title;
  }

  #capacity {
    grid-area: capacity;
  }

  #class-title {
    grid-area: class-title;
  }

  #class {
    grid-area: class;
  }

  #turbo-title {
    grid-area: turbo-title;
  }

  #turbo-op1 {
    grid-area: turbo-op1;
  }

  #turbo-op2 {
    grid-area: turbo-op2;
  }

  #registration-country-title {
    grid-area: registration-country-title;
  }

  #registration-country {
    grid-area: registration-country;
  }

  #counted-capacity-title {
    grid-area: counted-capacity-title;
  }

  #counted-capacity {
    grid-area: counted-capacity;
  }

  #empty {
    grid-area: empty;
  }

  #drive-title {
    grid-area: drive-title;
  }

  #drive-op1 {
    grid-area: drive-op1;
  }

  #drive-op2 {
    grid-area: drive-op2;
  }
}
</style>
