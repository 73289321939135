<template>
  <div id="invoice" class="component">
    <div id="title" class="title">Dane do wystawienia faktury</div>
    <div id="invoice-name-title">Imię i Nazwisko lub nazwa firmy</div>
    <div id="invoice-name" class="input-div"><input type="text" v-model="data.invoiceName" name="invoiceName"></div>
    <div id="invoice-address-title">Ulica, Nr</div>
    <div id="invoice-address" class="input-div"><input type="text" v-model="data.invoiceAddress" name="invoiceAddress"></div>
    <div id="invoice-postcode-title">Kod Pocztowy</div>
    <div id="invoice-postcode" class="input-div"><input type="text" v-model="data.invoicePostcode" name="invoicePostcode"></div>
    <div id="invoice-city-title">Miejscowość</div>
    <div id="invoice-city" class="input-div"><input type="text" v-model="data.invoiceCity" name="invoiceCity"></div>
    <div id="invoice-nip-title">NIP</div>
    <div id="invoice-nip" class="input-div"><input type="text" v-model="data.invoiceNip" name="invoiceNip"></div>
  </div>
</template>

<script>
export default {
  name: "RegistrationICE",
  props: {
    objectName: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      isAll: false,
      requiredFocus: false,
      data: {
        invoiceName: "",
        invoiceAddress: "",
        invoicePostcode: "",
        invoiceCity: "",
        invoiceNip: "",
      },
      required: {
        invoiceName: false,
        invoiceAddress: false,
        invoicePostcode: false,
        invoiceCity: false,
        invoiceNip: false,
      },
    }
  },
  methods: {
    focusRequired: function () {
      for (const [key, value] of Object.entries(this.required))
        if (value && !this.data[key]) {
          document.getElementsByName(key).forEach(el => el.classList.add("is-required"))
          this.requiredFocus = true
        }
    },
    clearRequiredFocus: function () {
      for (const [key, value] of Object.entries(this.required))
        if (value && this.data[key]) {
          document.getElementsByName(key).forEach(el => el.classList.remove("is-required"))
        }
    },
    autoFill: function(data) {
      this.data = {...data}
    }
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.isAll = true
        for (const [key, value] of Object.entries(this.required))
          if (value && !this.data[key]) {
            this.isAll = false;
            break
          }
        if(this.requiredFocus)
          this.clearRequiredFocus();
        this.$emit('update-data', {objectName: this.objectName, data: this.data})
        this.$emit('update-is-all', {objectName: this.objectName, data: this.isAll})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#invoice {
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr 2fr;
  grid-template-areas:
      "title title"
      "invoice-name-title invoice-name"
      "invoice-address-title invoice-address"
      "invoice-postcode-title invoice-postcode"
      "invoice-city-title invoice-city"
      "invoice-nip-title invoice-nip";

  #title {
    grid-area: title;
  }

  #invoice-name-title {
    grid-area: invoice-name-title;
  }

  #invoice-name {
    grid-area: invoice-name;
  }

  #invoice-address-title {
    grid-area: invoice-address-title;
  }

  #invoice-address {
    grid-area: invoice-address;
  }

  #invoice-postcode-title {
    grid-area: invoice-postcode-title;
  }

  #invoice-postcode {
    grid-area: invoice-postcode;
  }

  #invoice-city-title {
    grid-area: invoice-city-title;
  }

  #invoice-city {
    grid-area: invoice-city;
  }

  #invoice-nip-title {
    grid-area: invoice-nip-title;
  }

  #invoice-nip {
    grid-area: invoice-nip;
  }
}
</style>
