<template>
  <div id="competitors" class="component">

    <div id="empty"></div>
    <div id="driver-title" class="center">Kierowca</div>
    <div id="codriver-title" class="center">Pilot</div>

    <div id="surname">Nazwisko</div>
    <div id="driver-surname" class="input-div"><input type="text" v-model="data.driverSurname" name="driverSurname"></div>
    <div id="codriver-surname" class="input-div"><input type="text" v-model="data.codriverSurname" name="codriverSurname"></div>

    <div id="name">Imię</div>
    <div id="driver-name" class="input-div"><input type="text" v-model="data.driverName" name="driverName"></div>
    <div id="codriver-name" class="input-div"><input type="text" v-model="data.codriverName" name="codriverName"></div>

    <div id="birthdate">Data urodzenia</div>
    <div id="driver-birthdate" class="input-div"><input type="date" v-model="data.driverBirthdate" name="driverBirthdate"></div>
    <div id="codriver-birthdate" class="input-div"><input type="date" v-model="data.codriverBirthdate" name="codriverBirthdate"></div>

    <div id="address">Adres</div>
    <div id="driver-address" class="input-div"><input type="text" v-model="data.driverAddress" name="driverAddress"></div>
    <div id="codriver-address" class="input-div"><input type="text" v-model="data.codriverAddress" name="codriverAddress"></div>

    <div id="postcode">Kod pocztowy</div>
    <div id="driver-postcode" class="input-div"><input type="text" v-model="data.driverPostcode" name="driverPostcode"></div>
    <div id="codriver-postcode" class="input-div"><input type="text" v-model="data.codriverPostcode" name="codriverPostcode"></div>

    <div id="phone">Telefon kontaktowy</div>
    <div id="driver-phone" class="input-div"><input type="tel" v-model="data.driverPhone" name="driverPhone"></div>
    <div id="codriver-phone" class="input-div"><input type="tel" v-model="data.codriverPhone" name="codriverPhone"></div>

    <div id="sms-driver-title">Chcę otrzymywać wyniki SMS</div>
    <div id="sms-driver-op1" class="center">
      <input type="radio" name="sms-driver" class="better-radio" id="sms-driver-op1-radio" value="Tak"
             v-model="data.smsDriver" :class="[{ 'checked': data.smsDriver==='Tak' }]">
      <label for="sms-driver-op1-radio" name="smsDriver">
        <span>☑</span>
        Tak
      </label>
    </div>
    <div id="sms-driver-op2" class="center">
      <input type="radio" name="sms-driver" class="better-radio" id="sms-driver-op2-radio" value="Nie"
             v-model="data.smsDriver" :class="[{ 'checked': data.smsDriver==='Nie' }]">
      <label for="sms-driver-op2-radio" name="smsDriver">
        <span>☑</span>
        Nie
      </label>
    </div>
    <div id="sms-codriver-title">Chcę otrzymywać wyniki SMS</div>
    <div id="sms-codriver-op1" class="center">
      <input type="radio" name="sms-codriver" class="better-radio" id="sms-codriver-op1-radio" value="Tak"
             v-model="data.smsCodriver" :class="[{ 'checked': data.smsCodriver==='Tak' }]">
      <label for="sms-codriver-op1-radio" name="sms-codriver">
        <span>☑</span>
        Tak
      </label>
    </div>
    <div id="sms-codriver-op2" class="center">
      <input type="radio" name="sms-codriver" class="better-radio" id="sms-codriver-op2-radio" value="Nie"
             v-model="data.smsCodriver" :class="[{ 'checked': data.smsCodriver==='Nie' }]">
      <label for="sms-codriver-op2-radio" name="sms-codriver">
        <span>☑</span>
        Nie
      </label>
    </div>

    <div id="email">E-mail</div>
    <div id="driver-email" class="input-div"><input type="email" v-model="data.driverEmail" name="driverEmail"></div>
    <div id="codriver-email" class="input-div"><input type="email" v-model="data.codriverEmail" name="codriverEmail"></div>

    <div id="club">Przynależność klubowa</div>
    <div id="driver-club" class="input-div"><input type="text" v-model="data.driverClub" name="driverClub"></div>
    <div id="codriver-club" class="input-div"><input type="text" v-model="data.codriverClub" name="codriverClub"></div>

    <div id="license">Stopień i nr licencji*</div>
    <div id="driver-license" class="input-div"><input type="text" v-model="data.driverLicense" name="driverLicense"></div>
    <div id="codriver-license" class="input-div"><input type="text" v-model="data.codriverLicense" name="codriverLicense"></div>

    <div id="license-club">Wydana przez*</div>
    <div id="driver-license-club" class="input-div"><input type="text" v-model="data.driverLicenseClub" name="driverLicenseClub"></div>
    <div id="codriver-license-club" class="input-div"><input type="text" v-model="data.codriverLicenseClub" name="codriverLicenseClub"></div>

    <div id="d-license">Nr prawa jazdy</div>
    <div id="driver-d-license" class="input-div"><input type="text" v-model="data.driverDLicense" name="driverDLicense"></div>
    <div id="codriver-d-license" class="input-div"><input type="text" v-model="data.codriverDLicense" name="codriverDLicense"></div>

    <div id="d-license-country">Kraj wydania</div>
    <div id="driver-d-license-country" class="input-div"><input type="text" v-model="data.driverDLicenseCountry" name="driverDLicenseCountry"></div>
    <div id="codriver-d-license-country" class="input-div"><input type="text" v-model="data.codriverDLicenseCountry" name="codriverDLicenseCountry"></div>
  </div>
</template>

<script>
export default {
  name: "RegistrationCompetitors",

  props: {
    objectName: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      isAll: false,
      requiredFocus: false,
      data: {
        driverSurname: "",
        codriverSurname: "",
        driverName: "",
        codriverName: "",
        driverBirthdate: "",
        codriverBirthdate: "",
        driverAddress: "",
        codriverAddress: "",
        driverPostcode: "",
        codriverPostcode: "",
        driverPhone: "",
        codriverPhone: "",
        smsDriver: null,
        smsCodriver: null,
        driverEmail: "",
        codriverEmail: "",
        driverClub: "",
        codriverClub: "",
        driverLicense: "",
        codriverLicense: "",
        driverLicenseClub: "",
        codriverLicenseClub: "",
        driverDLicense: "",
        codriverDLicense: "",
        driverDLicenseCountry: "",
        codriverDLicenseCountry: "",
      },
      required: {
        driverSurname: true,
        codriverSurname: false,
        driverName: true,
        codriverName: false,
        driverBirthdate: true,
        codriverBirthdate: false,
        driverAddress: true,
        codriverAddress: false,
        driverPostcode: true,
        codriverPostcode: false,
        driverPhone: true,
        codriverPhone: false,
        smsDriver: true,
        smsCodriver: false,
        driverEmail: true,
        codriverEmail: false,
        driverClub: true,
        codriverClub: false,
        driverLicense: false,
        codriverLicense: false,
        driverLicenseClub: false,
        codriverLicenseClub: false,
        driverDLicense: true,
        codriverDLicense: false,
        driverDLicenseCountry: true,
        codriverDLicenseCountry: false,
      },
    }
  },
  methods: {
    focusRequired: function () {
      for (const [key, value] of Object.entries(this.required))
        if (value && !this.data[key]) {
          document.getElementsByName(key).forEach(el => el.classList.add("is-required"))
          this.requiredFocus = true
        }
    },
    clearRequiredFocus: function () {
      for (const [key, value] of Object.entries(this.required))
        if (value && this.data[key]) {
          document.getElementsByName(key).forEach(el => el.classList.remove("is-required"))
        }
    },
    autoFill: function(data) {
      this.data = {...data}
    }
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.isAll = true
        for (const [key, value] of Object.entries(this.required))
          if (value && !this.data[key]) {
            this.isAll = false;
            break
          }
        if(this.requiredFocus)
          this.clearRequiredFocus();
        this.$emit('update-data', {objectName: this.objectName, data: this.data})
        this.$emit('update-is-all', {objectName: this.objectName, data: this.isAll})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#competitors {
  grid-auto-rows: 1fr;
  grid-template-columns: 4fr 2fr 1fr 1fr 2fr 1fr 1fr;
  grid-template-areas:
    "empty driver-title driver-title driver-title codriver-title codriver-title codriver-title"
    "surname driver-surname driver-surname driver-surname codriver-surname codriver-surname codriver-surname"
    "name driver-name driver-name driver-name codriver-name codriver-name codriver-name"
    "birthdate driver-birthdate driver-birthdate driver-birthdate codriver-birthdate codriver-birthdate codriver-birthdate"
    "address driver-address driver-address driver-address codriver-address codriver-address codriver-address"
    "postcode driver-postcode driver-postcode driver-postcode codriver-postcode codriver-postcode codriver-postcode"
    "phone driver-phone driver-phone driver-phone codriver-phone codriver-phone codriver-phone"
    "phone sms-driver-title sms-driver-op1 sms-driver-op2 sms-codriver-title sms-codriver-op1 sms-codriver-op2"
    "email driver-email driver-email driver-email codriver-email  codriver-email codriver-email"
    "club driver-club driver-club driver-club codriver-club codriver-club codriver-club"
    "license driver-license driver-license driver-license codriver-license codriver-license codriver-license"
    "license-club driver-license-club driver-license-club driver-license-club codriver-license-club codriver-license-club codriver-license-club"
    "d-license driver-d-license driver-d-license driver-d-license codriver-d-license codriver-d-license codriver-d-license"
    "d-license-country driver-d-license-country driver-d-license-country driver-d-license-country codriver-d-license-country codriver-d-license-country codriver-d-license-country";

  #title {
    grid-area: title;
  }

  #empty {
    grid-area: empty;
  }

  #driver-title {
    grid-area: driver-title;
  }

  #codriver-title {
    grid-area: codriver-title;
  }

  #surname {
    grid-area: surname;
  }

  #driver-surname {
    grid-area: driver-surname;
  }

  #codriver-surname {
    grid-area: codriver-surname;
  }

  #name {
    grid-area: name;
  }

  #driver-name {
    grid-area: driver-name;
  }

  #codriver-name {
    grid-area: codriver-name;
  }

  #birthdate {
    grid-area: birthdate;
  }

  #driver-birthdate {
    grid-area: driver-birthdate;
  }

  #codriver-birthdate {
    grid-area: codriver-birthdate;
  }

  #address {
    grid-area: address;
  }

  #driver-address {
    grid-area: driver-address;
  }

  #codriver-address {
    grid-area: codriver-address;
  }

  #postcode {
    grid-area: postcode;
  }

  #driver-postcode {
    grid-area: driver-postcode;
  }

  #codriver-postcode {
    grid-area: codriver-postcode;
  }

  #phone {
    grid-area: phone;
  }

  #driver-phone {
    grid-area: driver-phone;
  }

  #codriver-phone {
    grid-area: codriver-phone;
  }

  #sms-driver-title {
    grid-area: sms-driver-title;
  }

  #sms-driver-op1 {
    grid-area: sms-driver-op1;
  }

  #sms-driver-op2 {
    grid-area: sms-driver-op2;
  }

  #sms-codriver-title {
    grid-area: sms-codriver-title;
  }

  #sms-codriver-op1 {
    grid-area: sms-codriver-op1;
  }

  #sms-codriver-op2 {
    grid-area: sms-codriver-op2;
  }

  #email {
    grid-area: email;
  }

  #driver-email {
    grid-area: driver-email;
  }

  #codriver-email {
    grid-area: codriver-email;
  }

  #club {
    grid-area: club;
  }

  #driver-club {
    grid-area: driver-club;
  }

  #codriver-club {
    grid-area: codriver-club;
  }

  #license {
    grid-area: license;
  }

  #driver-license {
    grid-area: driver-license;
  }

  #codriver-license {
    grid-area: codriver-license;
  }

  #license-club {
    grid-area: license-club;
  }

  #driver-license-club {
    grid-area: driver-license-club;
  }

  #codriver-license-club {
    grid-area: codriver-license-club;
  }

  #d-license {
    grid-area: d-license;
  }

  #driver-d-license {
    grid-area: driver-d-license;
  }

  #codriver-d-license {
    grid-area: codriver-d-license;
  }

  #d-license-country {
    grid-area: d-license-country;
  }

  #driver-d-license-country {
    grid-area: driver-d-license-country;
  }

  #codriver-d-license-country {
    grid-area: codriver-d-license-country;
  }
}
</style>
