<template>
  <div id="entry-fee" class="component">
    <div id="title" class="title">Wpisowe</div>
<!--    <div id="description" class="annotation">Aby zgłoszenie było ważne należy do niego dołączyć potwierdzenie wpłaty-->
<!--      właściwej kwoty na konto organizatora, tj. przekaz bankowy, potwierdzenie przelewu bankowego itp.-->
<!--    </div>-->
    <div id="entry-fee-term-title">Zgłoszenie w terminie:</div>
    <div id="entry-fee-term" class="input-div">
      <select v-model="data.term" name="term" disabled>
        <option v-for="item in terms" :value="item" :key="item.name">{{ item.name }}</option>
      </select>
    </div>
    <div id="advertisement-title">Reklama organizatora:</div>
    <div id="advertisement-op1" class="center">
      <input type="radio" name="advertisement" class="better-radio" id="advertisement-op1-radio" value="Tak"
             v-model="data.advertisement" :class="[{ 'checked': data.advertisement==='Tak' }]">
      <label for="advertisement-op1-radio" name="advertisement">
        <span>☑</span>
        Tak
      </label>
    </div>
    <div id="advertisement-op2" class="center">
      <input type="radio" name="advertisement" class="better-radio" id="advertisement-op2-radio" value="Nie"
             v-model="data.advertisement" :class="[{ 'checked': data.advertisement==='Nie' }]">
      <label for="advertisement-op2-radio" name="advertisement">
        <span>☑</span>
        Nie
      </label>
    </div>
    <div id="fee-type-1-title">Członek A. Małopolskiego Krosno</div>
    <div id="fee-type-1-op1" class="center">
      <input type="radio" name="fee-type-1" class="better-radio" id="fee-type-1-op1-radio" value="Tak"
             v-model="data.feeType1" :class="[{ 'checked': data.feeType1==='Tak' }]">
      <label for="fee-type-1-op1-radio" name="feeType1">
        <span>☑</span>
        Tak
      </label>
    </div>
    <div id="fee-type-1-op2" class="center">
      <input type="radio" name="fee-type-1" class="better-radio" id="fee-type-1-op2-radio" value="Nie"
             v-model="data.feeType1" :class="[{ 'checked': data.feeType1==='Nie' }]">
      <label for="fee-type-1-op2-radio" name="feeType1">
        <span>☑</span>
        Nie
      </label>
    </div>
    <div id="fee-type-2-title">Członek klubu zrzeszonego w PZM</div>
    <div id="fee-type-2-op1" class="center">
      <input type="radio" name="fee-type-2" class="better-radio" id="fee-type-2-op1-radio" value="Tak"
             v-model="data.feeType2" :class="[{ 'checked': data.feeType2==='Tak' }]">
      <label for="fee-type-2-op1-radio" name="feeType2">
        <span>☑</span>
        Tak
      </label>
    </div>
    <div id="fee-type-2-op2" class="center">
      <input type="radio" name="fee-type-2" class="better-radio" id="fee-type-2-op2-radio" value="Nie"
             v-model="data.feeType2" :class="[{ 'checked': data.feeType2==='Nie' }]">
      <label for="fee-type-2-op2-radio" name="feeType2">
        <span>☑</span>
        Nie
      </label>
    </div>
    <div id="empty"></div>
    <div id="amount-title">Kwota:</div>
    <div id="amount">{{ entryFeeAmount }}</div>
    <div id="bank-no-title">Przelew bankowy: (obowiązkowo dowód nadania)</div>
    <div id="bank-no" class="center" style="font-weight: bold;">{{ bankAccount }}</div>
    <div id="bank-title-title">Tytuł:</div>
    <div id="bank-title" class="center">{{ `${bankTitle} - [${competitorsData.driverName || ""} ${competitorsData.driverSurname || ""}]` }}</div>
  </div>
</template>

<script>
const config = require("@/const/config")
export default {
  name: "RegistrationEntryFee",
  props: {
    objectName: {
      type: String,
      required: true
    },
    entryFeeAmount: {
      type: String,
      required: true
    },
    competitorsData: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    const actualDate = new Date();
    for (const term of this.terms) {
      if (actualDate < new Date(term.deadline)) {
        this.data.term = term;
        break;
      }
    }
    if (!this.data.term)
      this.$emit('term-not-exist')
    else if (actualDate < new Date(this.openDate))
      this.$emit('is-too-early')
  },
  data() {
    return {
      isAll: false,
      requiredFocus: false,
      terms: config.terms,
      bankAccount: config.bankAccount,
      bankTitle: config.bankTitle,
      openDate: config.openDate,
      data: {
        term: null,
        advertisement: null,
        feeType1: null,
        feeType2: null,
      },
      required: {
        term: true,
        advertisement: true,
        feeType1: true,
        feeType2: true,
      },
    }
  },
  methods: {
    focusRequired: function () {
      for (const [key, value] of Object.entries(this.required))
        if (value && !this.data[key]) {
          document.getElementsByName(key).forEach(el => el.classList.add("is-required"))
          this.requiredFocus = true
        }
    },
    clearRequiredFocus: function () {
      for (const [key, value] of Object.entries(this.required))
        if (value && this.data[key]) {
          document.getElementsByName(key).forEach(el => el.classList.remove("is-required"))
        }
    },
    autoFill: function (data) {
      this.data = {...data}
      this.data.term.deadline = new Date(data.term.deadline).toISOString()
    }
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.isAll = true
        for (const [key, value] of Object.entries(this.required))
          if (value && !this.data[key]) {
            this.isAll = false;
            break
          }
        if (this.requiredFocus)
          this.clearRequiredFocus();
        this.$emit('update-data', {objectName: this.objectName, data: this.data})
        this.$emit('update-is-all', {objectName: this.objectName, data: this.isAll})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#entry-fee {
  grid-template-columns: 4fr 1fr 2fr 2fr;
  grid-template-areas:
      "title title title title"
      "description description description description"
      "... ... ... ..."
      "entry-fee-term-title entry-fee-term-title entry-fee-term entry-fee-term"
      "advertisement-title advertisement-title advertisement-op1 advertisement-op2"
      "fee-type-1-title fee-type-1-title fee-type-1-op1 fee-type-1-op2"
      "fee-type-2-title fee-type-2-title fee-type-2-op1 fee-type-2-op2"
      "empty empty amount-title amount"
      "bank-no-title bank-no bank-no bank-no"
      "bank-title-title bank-title bank-title bank-title";

  #title {
    grid-area: title;
  }

  #description {
    grid-area: description;
    font-size: 70%;
    padding-bottom: 1em;
  }

  #entry-fee-term-title {
    grid-area: entry-fee-term-title;
  }

  #entry-fee-term {
    grid-area: entry-fee-term;
  }

  #advertisement-title {
    grid-area: advertisement-title;
  }

  #advertisement-op1 {
    grid-area: advertisement-op1;
  }

  #advertisement-op2 {
    grid-area: advertisement-op2;
  }

  #fee-type-1-title {
    grid-area: fee-type-1-title;
  }

  #fee-type-1-op1 {
    grid-area: fee-type-1-op1;
  }

  #fee-type-1-op2 {
    grid-area: fee-type-1-op2;
  }

  #fee-type-2-title {
    grid-area: fee-type-2-title;
  }

  #fee-type-2-op1 {
    grid-area: fee-type-2-op1;
  }

  #fee-type-2-op2 {
    grid-area: fee-type-2-op2;
  }

  #empty {
    grid-area: empty;
  }

  #amount-title {
    grid-area: amount-title;
  }

  #amount {
    grid-area: amount;
  }

  #bank-no-title {
    grid-area: bank-no-title;
  }

  #bank-no {
    grid-area: bank-no;
  }

  #bank-title-title {
    grid-area: bank-title-title;
  }

  #bank-title {
    grid-area: bank-title;
  }
}
</style>
