<template>
  <div id="policy" class="component">
    <div id="title" class="title">POLISA</div>
    <div id="policy-title">Ubezpieczenie OC</div>
    <div id="policy-name-title" class="center">Nazwa ubezpieczyciela</div>
    <div id="policy-number-title" class="center">Numer polisy</div>
    <div id="policy-name" class="input-div"><input type="text" v-model="data.policyName" name="policyName"></div>
    <div id="policy-number" class="input-div"><input type="text" v-model="data.policyNumber" name="policyNumber"></div>
  </div>
</template>

<script>
export default {
  name: "RegistrationPolicy",
  props: {
    objectName: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      isAll: false,
      requiredFocus: false,
      data: {
        policyName: "",
        policyNumber: "",
      },
      required: {
        policyName: true,
        policyNumber: true,
      },
    }
  },
  methods: {
    focusRequired: function () {
      for (const [key, value] of Object.entries(this.required))
        if (value && !this.data[key]) {
          document.getElementsByName(key).forEach(el => el.classList.add("is-required"))
          this.requiredFocus = true
        }
    },
    clearRequiredFocus: function () {
      for (const [key, value] of Object.entries(this.required))
        if (value && this.data[key]) {
          document.getElementsByName(key).forEach(el => el.classList.remove("is-required"))
        }
    },
    autoFill: function(data) {
      this.data = {...data}
    }
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.isAll = true
        for (const [key, value] of Object.entries(this.required))
          if (value && !this.data[key]) {
            this.isAll = false;
            break
          }
        if(this.requiredFocus)
          this.clearRequiredFocus();
        this.$emit('update-data', {objectName: this.objectName, data: this.data})
        this.$emit('update-is-all', {objectName: this.objectName, data: this.isAll})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#policy {
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
      "title title title"
      "policy-title policy-title-name policy-title-number"
      "policy-title policy-name policy-number";

  #title {
    grid-area: title;
  }

  #policy-title {
    grid-area: policy-title;
  }

  #policy-title-name {
    grid-area: policy-title-name;
  }

  #policy-title-number {
    grid-area: policy-title-number;
  }

  #policy-name {
    grid-area: policy-name;
  }

  #policy-number {
    grid-area: policy-number;
  }
}
</style>
