<template>
  <div id="rounds" class="component">
    <div id="title" class="title">Zgłoszenie indywidualne</div>
    <div id="round1-title">4. runda MOR w KJS – 23.11.2024</div>
    <div id="round1-op1" class="center">
      <input type="radio" name="round1" class="better-radio" id="round1-op1-radio" value="Tak"
             v-model="data.round1" :class="[{ 'checked': data.round1==='Tak' }]">
      <label for="round1-op1-radio" name="round1">
        <span>☑</span>
        Tak
      </label>
    </div>
    <div id="round1-op2" class="center">
      <input type="radio" name="round1" class="better-radio" id="round1-op2-radio" value="Nie"
             v-model="data.round1" :class="[{ 'checked': data.round1==='Nie' }]">
      <label for="round1-op2-radio" name="round1">
        <span>☑</span>
        Nie
      </label>
    </div>
    <div id="round2-title">5. runda MOR w KJS – 24.11.2024</div>
    <div id="round2-op1" class="center">
      <input type="radio" name="round2" class="better-radio" id="round2-op1-radio" value="Tak"
             v-model="data.round2" :class="[{ 'checked': data.round2==='Tak' }]">
      <label for="round2-op1-radio" name="round2">
        <span>☑</span>
        Tak
      </label>
    </div>
    <div id="round2-op2" class="center">
      <input type="radio" name="round2" class="better-radio" id="round2-op2-radio" value="Nie"
             v-model="data.round2" :class="[{ 'checked': data.round2==='Nie' }]">
      <label for="round2-op2-radio" name="round2">
        <span>☑</span>
        Nie
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegistrationRounds",
  props: {
    objectName: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      isAll: false,
      requiredFocus: false,
      data: {
        round1: null,
        round2: null,
      },
      required: {
        round1: true,
        round2: true,
      },
    }
  },
  methods: {
    focusRequired: function () {
      for (const [key, value] of Object.entries(this.required))
        if (value && !this.data[key]) {
          document.getElementsByName(key).forEach(el => el.classList.add("is-required"))
          this.requiredFocus = true
        }
    },
    clearRequiredFocus: function () {
      for (const [key, value] of Object.entries(this.required))
        if (value && this.data[key]) {
          document.getElementsByName(key).forEach(el => el.classList.remove("is-required"))
        }
    },
    autoFill: function(data) {
      this.data = {...data}
    }
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.isAll = true
        for (const [key, value] of Object.entries(this.required))
          if (value && !this.data[key]) {
            this.isAll = false;
            break
          }
        if(this.requiredFocus)
          this.clearRequiredFocus();
        this.$emit('update-data', {objectName: this.objectName, data: this.data})
        this.$emit('update-is-all', {objectName: this.objectName, data: this.isAll})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#rounds {
  grid-auto-rows: 1fr;
  grid-template-columns: 4fr 1fr 1fr 4fr 1fr 1fr;
  grid-template-areas:
      "title title title title title title"
      "round1-title round1-op1 round1-op2 round2-title round2-op1 round2-op2";

  #title {
    grid-area: title;
  }
  #round1-title {
    grid-area: round1-title;
  }
  #round1-op1 {
    grid-area: round1-op1;
  }
  #round1-op2 {
    grid-area: round1-op2;
  }
  #round2-title {
    grid-area: round2-title;
  }
  #round2-op1 {
    grid-area: round2-op1;
  }
  #round2-op2 {
    grid-area: round2-op2;
  }
}
</style>
