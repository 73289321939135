<template>
  <div id="signatures" class="component">
    <div id="date">Data i miejsce</div>
    <div id="driver">Podpis kierowcy</div>
    <div id="codriver">Podpis pilota</div>
  </div>
</template>

<script>
export default {
  name: "RegistrationSignatures",
  props: {
  }
}
</script>

<style lang="scss" scoped>
#signatures {
  grid-auto-rows: 2fr;
  grid-template-columns: 3fr 3fr 3fr;
  grid-template-areas: "date driver codriver";
  div {
    padding-bottom: 2em;
  }

  #date {
    grid-area: date;
  }
  #driver {
    grid-area: driver;
  }
  #codriver {
    grid-area: codriver;
  }
}
</style>